import "./style.scss";
import { isDefined } from "@clipboard-health/util-ts";
import { IonFooter } from "@ionic/react";
import { CbhFeatureFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { useGetShiftCancelFeedback } from "@src/appV2/Shifts/MyShiftUnavailable";
import { ShiftStateInfo } from "@src/appV2/Shifts/Shift/ShiftState/ShiftStateInfo";
import { Shift } from "@src/appV2/Shifts/Shift/types";
import { useState } from "react";

import { CheckAttendance } from "./checkAttendance";
import { mapShiftStateDependencies } from "./mapShiftStateDependencies";
import { ShiftActionFooter } from "./shiftActionFooter";
import { ShiftActionFooterProps } from "./types";
import { RecordTimeButton } from "../components/recordTimeButton";

export const AttendancePolicyShiftActionFooter: React.VFC<ShiftActionFooterProps> = ({
  shift,
  hasMissingDocs,
  canShowUploadButton,
  shiftStateData,
  ...recordTimeProps
}) => {
  // FIXME: Change all components to use appV2 Shift.
  const shiftCancelFeedback = useGetShiftCancelFeedback(shift as unknown as Shift);
  const [isConfirmAttendanceVisible, setIsConfirmAttendanceVisible] = useState(false);

  const shiftStateDependencies = shift ? mapShiftStateDependencies(shift) : undefined;

  const ldFlags = useCbhFlags();
  const isShiftStateInfoEnabled = ldFlags[CbhFeatureFlag.SHOW_SHIFT_STATUS_FOOTER_INFO];

  return (
    <IonFooter aria-label="Shift Actions" className="attendance-policy-shift-action-footer">
      {isShiftStateInfoEnabled && shiftStateDependencies ? (
        <ShiftStateInfo
          shiftStateData={shiftStateData}
          shiftStateDependencies={shiftStateDependencies}
          shiftCancelFeedback={shiftCancelFeedback}
        />
      ) : null}
      {canShowUploadButton && (
        <RecordTimeButton shift={shift} shiftStateData={shiftStateData} {...recordTimeProps} />
      )}
      <CheckAttendance shift={shift} onVisibilityChange={setIsConfirmAttendanceVisible} />

      {isDefined(shift) ? (
        <ShiftActionFooter
          shift={shift}
          shiftStateData={shiftStateData}
          hasMissingDocs={hasMissingDocs}
          isConfirmAttendanceVisible={isConfirmAttendanceVisible}
        />
      ) : null}
    </IonFooter>
  );
};
