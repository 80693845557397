import { isDefined } from "@clipboard-health/util-ts";
import { ActionSheetButton, IonActionSheet, IonAlert } from "@ionic/react";
import { CancelShiftPayload, selfCancelShift } from "@src/app/api/shift";
import { AttendanceScoreCancellationPolicyResponse } from "@src/app/attendancePolicy/api/getAttendanceScoreCancellationPolicy";
import { useAttendanceScoreCancellationPolicy } from "@src/app/attendancePolicy/api/useAttendanceScoreCancellationPolicy";
import { getOrWatchCurrentLocation } from "@src/app/common/location";
import { useGeolocationTrackingForShiftsEnabled } from "@src/appV2/Facilities/useGeoLocationTrackingForShiftEnabled";
import { CbhFeatureFlag, FEATURE_FLAG_DEFAULT_VALUES, useCbhFlag } from "@src/appV2/FeatureFlags";
import { deprecatedDoNotUseLogError, logEvent } from "@src/appV2/lib/analytics";
import {
  SelfCancelShiftErrorResponse,
  Shift,
  selfCancelShiftErrorResponseSchema,
} from "@src/appV2/Shifts/Shift/types";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { USER_EVENTS } from "@src/constants";
import { differenceInMinutes, parseISO } from "date-fns";
import pluralize from "pluralize";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import { SentHomeRequestModal } from "./sentHomeRequestModal";
import {
  HOURS_FROM_SHIFT_START_TO_INCLUDE_LOCATION,
  SHIFT_CANCEL_DEFAULT_FAILURE_MESSAGE,
  ShiftSelfCancelErrors,
} from "../constants";

function getShiftCancellationMessage(props: {
  canCancelWithoutPenalty: boolean;
  undoBookingTimeThresholdInSeconds: number;
  cancellationPolicyData?: AttendanceScoreCancellationPolicyResponse;
}): string {
  const { canCancelWithoutPenalty, cancellationPolicyData, undoBookingTimeThresholdInSeconds } =
    props;
  if (canCancelWithoutPenalty) {
    const undoBookingTimeThresholdInMinutes = Math.ceil(undoBookingTimeThresholdInSeconds / 60);
    return `This cancellation will not penalize your Attendance Score since it is within ${undoBookingTimeThresholdInMinutes} ${pluralize(
      "minute",
      undoBookingTimeThresholdInMinutes
    )} of you booking the shift`;
  }
  if (cancellationPolicyData?.policy?.points && isDefined(cancellationPolicyData?.message)) {
    return cancellationPolicyData.message;
  }
  return "";
}

interface Props {
  showCancelActionSheet: boolean;
  setShowCancelActionSheet: (v: boolean) => void;
  shift: Shift;
  isSentHomeEnabled: boolean;
  canCancelWithoutPenalty: boolean;
  clientCancellationTime: Date;
}

export function AttendancePolicyCancelActionSheet(props: Props) {
  const {
    showCancelActionSheet,
    setShowCancelActionSheet,
    shift,
    isSentHomeEnabled,
    canCancelWithoutPenalty,
    clientCancellationTime,
  } = props;

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [shiftCancellingIsInProgress, setCancellingShift] = useState(false);
  const [openSentHomeRequestModal, setOpenSentHomeRequestModal] = useState(false);
  const history = useHistory();
  const worker = useDefinedWorker();
  const [showCancelShiftAlert, setShowCancelShiftAlert] = useState(false);

  const undoBookingTimeThresholdInSeconds = useCbhFlag(CbhFeatureFlag.UNDO_BOOKING_TIME_THRESHOLD, {
    defaultValue: FEATURE_FLAG_DEFAULT_VALUES[CbhFeatureFlag.UNDO_BOOKING_TIME_THRESHOLD],
  });

  const shiftCancellationLeadTimeInHours = (
    differenceInMinutes(parseISO(shift.start), new Date()) / 60
  ).toFixed(2);

  const { data: cancellationPolicyData } = useAttendanceScoreCancellationPolicy(
    Number(shiftCancellationLeadTimeInHours),
    canCancelWithoutPenalty,
    shift.facilityId,
    worker.userId,
    {
      onError: (error) => {
        if (error instanceof Error) {
          deprecatedDoNotUseLogError({
            message: JSON.stringify({
              error: error?.stack || error,
              message: `Can't get attendance score cancellation policy - ${error?.message}`,
            }),
          });
        }
      },
    }
  );

  const geolocationTrackingForShiftsEnabled = useGeolocationTrackingForShiftsEnabled(shift);

  const actionButtons: ActionSheetButton[] = [];

  if (isSentHomeEnabled) {
    const sentHomeButton = {
      text: "No, I was sent home",
      role: "selected",
      handler: () => {
        setShowCancelActionSheet(false);
        setOpenSentHomeRequestModal(true);
      },
    };
    actionButtons.push(sentHomeButton);
  }

  const onCancelShift = async () => {
    if (shiftCancellingIsInProgress) {
      return;
    }

    setCancellingShift(true);
    const payload: CancelShiftPayload = {
      shiftId: shift._id,
      reasonType: "NONE",
      reasonDescription: "",
      clientCancellationTime: clientCancellationTime.toISOString(),
    };
    logEvent(USER_EVENTS.CANCEL_SHIFT_BUTTON_CLICKED, {
      shiftId: shift._id,
    });

    if (
      Number(shiftCancellationLeadTimeInHours) <= HOURS_FROM_SHIFT_START_TO_INCLUDE_LOCATION &&
      geolocationTrackingForShiftsEnabled
    ) {
      const { location, error: positionError } = await getOrWatchCurrentLocation();

      if (!positionError) {
        payload.coordinates = location;
      }
    }

    try {
      await selfCancelShift(payload);
      history.replace("/home/myShifts");
    } catch (error) {
      if (
        isDefined(error?.response?.body) &&
        selfCancelShiftErrorResponseSchema.safeParse(error.response.body).success
      ) {
        const errorResponse = error.response.body as SelfCancelShiftErrorResponse;
        const selfCancelErrorCode = errorResponse.errors[0]?.code;
        if (isDefined(selfCancelErrorCode) && selfCancelErrorCode in ShiftSelfCancelErrors) {
          setErrorMessage(ShiftSelfCancelErrors[selfCancelErrorCode]);
        } else {
          setErrorMessage(SHIFT_CANCEL_DEFAULT_FAILURE_MESSAGE);
        }
      } else {
        setErrorMessage(SHIFT_CANCEL_DEFAULT_FAILURE_MESSAGE);
      }
    }
  };

  return (
    <>
      <IonActionSheet
        data-testid="attendance-policy-cancel-action-sheet"
        header={canCancelWithoutPenalty ? "Undo booking?" : "Cancel your shift?"}
        subHeader={getShiftCancellationMessage({
          canCancelWithoutPenalty,
          undoBookingTimeThresholdInSeconds,
          cancellationPolicyData,
        })}
        mode="ios"
        isOpen={showCancelActionSheet}
        onDidDismiss={() => setShowCancelActionSheet(false)}
        cssClass={`attendance-policy-cancel-action-sheet ${
          isSentHomeEnabled ? "sent-home-btn-outline" : "shift-cancel-btn-outline"
        }`}
        buttons={[
          ...actionButtons,
          {
            text: shiftCancellingIsInProgress ? "Cancelling shift" : "Cancel shift",
            role: "destructive",
            handler: () => setShowCancelShiftAlert(true),
          },
          {
            text: "Don't cancel",
            role: "cancel",
            handler: () => setShowCancelActionSheet(false),
          },
        ]}
      />
      <SentHomeRequestModal
        isOpen={openSentHomeRequestModal}
        closeModal={() => {
          setOpenSentHomeRequestModal(false);
        }}
        shift={shift}
      />
      <IonAlert
        mode="ios"
        isOpen={isDefined(errorMessage)}
        header="Error"
        message={errorMessage ?? ""}
        onDidDismiss={() => history.replace("/home/myShifts")}
        buttons={[
          {
            text: "Okay",
            role: "confirm",
            handler: async () => {
              history.replace("/home/myShifts");
            },
          },
        ]}
      />
      <IonAlert
        data-testid="attendance-policy-cancel-confirmation-alert"
        mode="ios"
        isOpen={showCancelShiftAlert}
        onDidDismiss={() => setShowCancelShiftAlert(false)}
        header="Are you sure?"
        message="Cancelling this shift will remove it from your calendar on confirmation."
        buttons={[
          {
            text: "Go back",
            role: "cancel",
          },
          {
            text: "Confirm",
            role: "confirm",
            handler: async () => {
              await onCancelShift();
            },
          },
        ]}
      />
    </>
  );
}
